export const KDropdown = () => import('../../components/KDropdown.vue' /* webpackChunkName: "components/k-dropdown" */).then(c => wrapFunctional(c.default || c))
export const KFooter = () => import('../../components/KFooter.vue' /* webpackChunkName: "components/k-footer" */).then(c => wrapFunctional(c.default || c))
export const KInfo = () => import('../../components/KInfo.vue' /* webpackChunkName: "components/k-info" */).then(c => wrapFunctional(c.default || c))
export const KMessage = () => import('../../components/KMessage.vue' /* webpackChunkName: "components/k-message" */).then(c => wrapFunctional(c.default || c))
export const KModal = () => import('../../components/KModal.vue' /* webpackChunkName: "components/k-modal" */).then(c => wrapFunctional(c.default || c))
export const KSidebar = () => import('../../components/KSidebar.vue' /* webpackChunkName: "components/k-sidebar" */).then(c => wrapFunctional(c.default || c))
export const KSwitch = () => import('../../components/KSwitch.vue' /* webpackChunkName: "components/k-switch" */).then(c => wrapFunctional(c.default || c))
export const KSwitchButton = () => import('../../components/KSwitchButton.vue' /* webpackChunkName: "components/k-switch-button" */).then(c => wrapFunctional(c.default || c))
export const KTooltip = () => import('../../components/KTooltip.vue' /* webpackChunkName: "components/k-tooltip" */).then(c => wrapFunctional(c.default || c))
export const ListScrollDashboard = () => import('../../components/ListScrollDashboard.vue' /* webpackChunkName: "components/list-scroll-dashboard" */).then(c => wrapFunctional(c.default || c))
export const SubscribeDrawer = () => import('../../components/SubscribeDrawer.vue' /* webpackChunkName: "components/subscribe-drawer" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionManager = () => import('../../components/SubscriptionManager.vue' /* webpackChunkName: "components/subscription-manager" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionManagerLight = () => import('../../components/SubscriptionManagerLight.vue' /* webpackChunkName: "components/subscription-manager-light" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const UnSubscribeDrawer = () => import('../../components/UnSubscribeDrawer.vue' /* webpackChunkName: "components/un-subscribe-drawer" */).then(c => wrapFunctional(c.default || c))
export const UnsubscribeBox = () => import('../../components/UnsubscribeBox.vue' /* webpackChunkName: "components/unsubscribe-box" */).then(c => wrapFunctional(c.default || c))
export const UnsubscribeBoxLight = () => import('../../components/UnsubscribeBoxLight.vue' /* webpackChunkName: "components/unsubscribe-box-light" */).then(c => wrapFunctional(c.default || c))
export const ProfileTile = () => import('../../components/profile-tile.vue' /* webpackChunkName: "components/profile-tile" */).then(c => wrapFunctional(c.default || c))
export const Share = () => import('../../components/share.vue' /* webpackChunkName: "components/share" */).then(c => wrapFunctional(c.default || c))
export const StickyBottomBar = () => import('../../components/sticky-bottom-bar.vue' /* webpackChunkName: "components/sticky-bottom-bar" */).then(c => wrapFunctional(c.default || c))
export const Author = () => import('../../components/author/Author.vue' /* webpackChunkName: "components/author" */).then(c => wrapFunctional(c.default || c))
export const AuthorAudience = () => import('../../components/author/AuthorAudience.vue' /* webpackChunkName: "components/author-audience" */).then(c => wrapFunctional(c.default || c))
export const AuthorCalculator = () => import('../../components/author/AuthorCalculator.vue' /* webpackChunkName: "components/author-calculator" */).then(c => wrapFunctional(c.default || c))
export const AuthorEditor = () => import('../../components/author/AuthorEditor.vue' /* webpackChunkName: "components/author-editor" */).then(c => wrapFunctional(c.default || c))
export const AuthorHeader = () => import('../../components/author/AuthorHeader.vue' /* webpackChunkName: "components/author-header" */).then(c => wrapFunctional(c.default || c))
export const AuthorJoinUs = () => import('../../components/author/AuthorJoinUs.vue' /* webpackChunkName: "components/author-join-us" */).then(c => wrapFunctional(c.default || c))
export const AuthorPicture = () => import('../../components/author/AuthorPicture.vue' /* webpackChunkName: "components/author-picture" */).then(c => wrapFunctional(c.default || c))
export const AuthorSection = () => import('../../components/author/AuthorSection.vue' /* webpackChunkName: "components/author-section" */).then(c => wrapFunctional(c.default || c))
export const ContactAuthorButton = () => import('../../components/buttons/ContactAuthorButton.vue' /* webpackChunkName: "components/contact-author-button" */).then(c => wrapFunctional(c.default || c))
export const KButton = () => import('../../components/buttons/KButton.vue' /* webpackChunkName: "components/k-button" */).then(c => wrapFunctional(c.default || c))
export const Like = () => import('../../components/buttons/Like.vue' /* webpackChunkName: "components/like" */).then(c => wrapFunctional(c.default || c))
export const SubscribeButton = () => import('../../components/buttons/SubscribeButton.vue' /* webpackChunkName: "components/subscribe-button" */).then(c => wrapFunctional(c.default || c))
export const RecommendCard = () => import('../../components/cards/RecommendCard.vue' /* webpackChunkName: "components/recommend-card" */).then(c => wrapFunctional(c.default || c))
export const CategoryHeader = () => import('../../components/category/CategoryHeader.vue' /* webpackChunkName: "components/category-header" */).then(c => wrapFunctional(c.default || c))
export const CommentCharacterCount = () => import('../../components/comments/CommentCharacterCount.vue' /* webpackChunkName: "components/comment-character-count" */).then(c => wrapFunctional(c.default || c))
export const CommentEditor = () => import('../../components/comments/CommentEditor.vue' /* webpackChunkName: "components/comment-editor" */).then(c => wrapFunctional(c.default || c))
export const CommentField = () => import('../../components/comments/CommentField.vue' /* webpackChunkName: "components/comment-field" */).then(c => wrapFunctional(c.default || c))
export const CommentPreview = () => import('../../components/comments/CommentPreview.vue' /* webpackChunkName: "components/comment-preview" */).then(c => wrapFunctional(c.default || c))
export const CommentShowMore = () => import('../../components/comments/CommentShowMore.vue' /* webpackChunkName: "components/comment-show-more" */).then(c => wrapFunctional(c.default || c))
export const CommentsBlock = () => import('../../components/comments/CommentsBlock.vue' /* webpackChunkName: "components/comments-block" */).then(c => wrapFunctional(c.default || c))
export const EditorPreview = () => import('../../components/editor/EditorPreview.vue' /* webpackChunkName: "components/editor-preview" */).then(c => wrapFunctional(c.default || c))
export const PublishDrawer = () => import('../../components/editor/PublishDrawer.vue' /* webpackChunkName: "components/publish-drawer" */).then(c => wrapFunctional(c.default || c))
export const TipTapEditor = () => import('../../components/editor/TipTapEditor.vue' /* webpackChunkName: "components/tip-tap-editor" */).then(c => wrapFunctional(c.default || c))
export const TipTapRender = () => import('../../components/editor/TipTapRender.vue' /* webpackChunkName: "components/tip-tap-render" */).then(c => wrapFunctional(c.default || c))
export const EmbedWidget = () => import('../../components/embed/EmbedWidget.vue' /* webpackChunkName: "components/embed-widget" */).then(c => wrapFunctional(c.default || c))
export const Error = () => import('../../components/errors/Error.vue' /* webpackChunkName: "components/error" */).then(c => wrapFunctional(c.default || c))
export const OverlayDemo = () => import('../../components/flip-feat/OverlayDemo.vue' /* webpackChunkName: "components/overlay-demo" */).then(c => wrapFunctional(c.default || c))
export const AskPasswordResetForm = () => import('../../components/form/AskPasswordResetForm.vue' /* webpackChunkName: "components/ask-password-reset-form" */).then(c => wrapFunctional(c.default || c))
export const ConfigButtonForm = () => import('../../components/form/ConfigButtonForm.vue' /* webpackChunkName: "components/config-button-form" */).then(c => wrapFunctional(c.default || c))
export const DirectSignup = () => import('../../components/form/DirectSignup.vue' /* webpackChunkName: "components/direct-signup" */).then(c => wrapFunctional(c.default || c))
export const KField = () => import('../../components/form/KField.vue' /* webpackChunkName: "components/k-field" */).then(c => wrapFunctional(c.default || c))
export const LoginForm = () => import('../../components/form/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c))
export const PublishForm = () => import('../../components/form/PublishForm.vue' /* webpackChunkName: "components/publish-form" */).then(c => wrapFunctional(c.default || c))
export const SignupForm = () => import('../../components/form/SignupForm.vue' /* webpackChunkName: "components/signup-form" */).then(c => wrapFunctional(c.default || c))
export const SubscribeForm = () => import('../../components/form/SubscribeForm.vue' /* webpackChunkName: "components/subscribe-form" */).then(c => wrapFunctional(c.default || c))
export const HeroBrushSection = () => import('../../components/homepage/HeroBrushSection.vue' /* webpackChunkName: "components/hero-brush-section" */).then(c => wrapFunctional(c.default || c))
export const B2KIcon = () => import('../../components/icons/B2KIcon.vue' /* webpackChunkName: "components/b2-k-icon" */).then(c => wrapFunctional(c.default || c))
export const KIcon = () => import('../../components/icons/KIcon.vue' /* webpackChunkName: "components/k-icon" */).then(c => wrapFunctional(c.default || c))
export const KImage = () => import('../../components/image/KImage.vue' /* webpackChunkName: "components/k-image" */).then(c => wrapFunctional(c.default || c))
export const AboutImagePicker = () => import('../../components/input/AboutImagePicker.vue' /* webpackChunkName: "components/about-image-picker" */).then(c => wrapFunctional(c.default || c))
export const AutoCompleteInput = () => import('../../components/input/AutoCompleteInput.vue' /* webpackChunkName: "components/auto-complete-input" */).then(c => wrapFunctional(c.default || c))
export const CategoriesPicker = () => import('../../components/input/CategoriesPicker.vue' /* webpackChunkName: "components/categories-picker" */).then(c => wrapFunctional(c.default || c))
export const ColorPicker = () => import('../../components/input/ColorPicker.vue' /* webpackChunkName: "components/color-picker" */).then(c => wrapFunctional(c.default || c))
export const CoverPicker = () => import('../../components/input/CoverPicker.vue' /* webpackChunkName: "components/cover-picker" */).then(c => wrapFunctional(c.default || c))
export const DomainPicker = () => import('../../components/input/DomainPicker.vue' /* webpackChunkName: "components/domain-picker" */).then(c => wrapFunctional(c.default || c))
export const ImagePicker = () => import('../../components/input/ImagePicker.vue' /* webpackChunkName: "components/image-picker" */).then(c => wrapFunctional(c.default || c))
export const KCheckbox = () => import('../../components/input/KCheckbox.vue' /* webpackChunkName: "components/k-checkbox" */).then(c => wrapFunctional(c.default || c))
export const KUploadFile = () => import('../../components/input/KUploadFile.vue' /* webpackChunkName: "components/k-upload-file" */).then(c => wrapFunctional(c.default || c))
export const ResizableTextarea = () => import('../../components/input/ResizableTextarea.vue' /* webpackChunkName: "components/resizable-textarea" */).then(c => wrapFunctional(c.default || c))
export const SearchField = () => import('../../components/input/SearchField.vue' /* webpackChunkName: "components/search-field" */).then(c => wrapFunctional(c.default || c))
export const DarkModePicker = () => import('../../components/input/dark-mode-picker.vue' /* webpackChunkName: "components/dark-mode-picker" */).then(c => wrapFunctional(c.default || c))
export const LandingCardPublication = () => import('../../components/landing/LandingCardPublication.vue' /* webpackChunkName: "components/landing-card-publication" */).then(c => wrapFunctional(c.default || c))
export const LandingHeader = () => import('../../components/landing/LandingHeader.vue' /* webpackChunkName: "components/landing-header" */).then(c => wrapFunctional(c.default || c))
export const LandingInvitation = () => import('../../components/landing/LandingInvitation.vue' /* webpackChunkName: "components/landing-invitation" */).then(c => wrapFunctional(c.default || c))
export const DropdownNavbar = () => import('../../components/navbar/DropdownNavbar.vue' /* webpackChunkName: "components/dropdown-navbar" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/navbar/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const NavbarDashboard = () => import('../../components/navbar/NavbarDashboard.vue' /* webpackChunkName: "components/navbar-dashboard" */).then(c => wrapFunctional(c.default || c))
export const NavbarPublic = () => import('../../components/navbar/NavbarPublic.vue' /* webpackChunkName: "components/navbar-public" */).then(c => wrapFunctional(c.default || c))
export const NavbarRegister = () => import('../../components/navbar/NavbarRegister.vue' /* webpackChunkName: "components/navbar-register" */).then(c => wrapFunctional(c.default || c))
export const NavbarStudio = () => import('../../components/navbar/NavbarStudio.vue' /* webpackChunkName: "components/navbar-studio" */).then(c => wrapFunctional(c.default || c))
export const NewNavbar = () => import('../../components/navbar/NewNavbar.vue' /* webpackChunkName: "components/new-navbar" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/onboarding/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const OnboardingNewsletter = () => import('../../components/onboarding/OnboardingNewsletter.vue' /* webpackChunkName: "components/onboarding-newsletter" */).then(c => wrapFunctional(c.default || c))
export const Progress = () => import('../../components/onboarding/Progress.vue' /* webpackChunkName: "components/progress" */).then(c => wrapFunctional(c.default || c))
export const KPagination = () => import('../../components/pagination/KPagination.vue' /* webpackChunkName: "components/k-pagination" */).then(c => wrapFunctional(c.default || c))
export const KPaginationList = () => import('../../components/pagination/KPaginationList.vue' /* webpackChunkName: "components/k-pagination-list" */).then(c => wrapFunctional(c.default || c))
export const PostAuthorCover = () => import('../../components/post/PostAuthorCover.vue' /* webpackChunkName: "components/post-author-cover" */).then(c => wrapFunctional(c.default || c))
export const PostAuthorDetails = () => import('../../components/post/PostAuthorDetails.vue' /* webpackChunkName: "components/post-author-details" */).then(c => wrapFunctional(c.default || c))
export const PostCounter = () => import('../../components/post/PostCounter.vue' /* webpackChunkName: "components/post-counter" */).then(c => wrapFunctional(c.default || c))
export const PostCounterDetails = () => import('../../components/post/PostCounterDetails.vue' /* webpackChunkName: "components/post-counter-details" */).then(c => wrapFunctional(c.default || c))
export const PostCover = () => import('../../components/post/PostCover.vue' /* webpackChunkName: "components/post-cover" */).then(c => wrapFunctional(c.default || c))
export const PostDropdown = () => import('../../components/post/PostDropdown.vue' /* webpackChunkName: "components/post-dropdown" */).then(c => wrapFunctional(c.default || c))
export const PostInfo = () => import('../../components/post/PostInfo.vue' /* webpackChunkName: "components/post-info" */).then(c => wrapFunctional(c.default || c))
export const PostLikeButton = () => import('../../components/post/PostLikeButton.vue' /* webpackChunkName: "components/post-like-button" */).then(c => wrapFunctional(c.default || c))
export const PostNavbar = () => import('../../components/post/PostNavbar.vue' /* webpackChunkName: "components/post-navbar" */).then(c => wrapFunctional(c.default || c))
export const PostPinned = () => import('../../components/post/PostPinned.vue' /* webpackChunkName: "components/post-pinned" */).then(c => wrapFunctional(c.default || c))
export const PostPreview = () => import('../../components/post/PostPreview.vue' /* webpackChunkName: "components/post-preview" */).then(c => wrapFunctional(c.default || c))
export const PostPreviewDashboard = () => import('../../components/post/PostPreviewDashboard.vue' /* webpackChunkName: "components/post-preview-dashboard" */).then(c => wrapFunctional(c.default || c))
export const PostShareTooltip = () => import('../../components/post/PostShareTooltip.vue' /* webpackChunkName: "components/post-share-tooltip" */).then(c => wrapFunctional(c.default || c))
export const PostStatusDropdown = () => import('../../components/post/PostStatusDropdown.vue' /* webpackChunkName: "components/post-status-dropdown" */).then(c => wrapFunctional(c.default || c))
export const PostVisivilityLevel = () => import('../../components/post/PostVisivilityLevel.vue' /* webpackChunkName: "components/post-visivility-level" */).then(c => wrapFunctional(c.default || c))
export const ProfileDisplay = () => import('../../components/profile/ProfileDisplay.vue' /* webpackChunkName: "components/profile-display" */).then(c => wrapFunctional(c.default || c))
export const ProfilePasswordForm = () => import('../../components/profile/ProfilePasswordForm.vue' /* webpackChunkName: "components/profile-password-form" */).then(c => wrapFunctional(c.default || c))
export const ProfileUserForm = () => import('../../components/profile/ProfileUserForm.vue' /* webpackChunkName: "components/profile-user-form" */).then(c => wrapFunctional(c.default || c))
export const HeaderPublication = () => import('../../components/publication/HeaderPublication.vue' /* webpackChunkName: "components/header-publication" */).then(c => wrapFunctional(c.default || c))
export const PublicationAuthorPresentation = () => import('../../components/publication/PublicationAuthorPresentation.vue' /* webpackChunkName: "components/publication-author-presentation" */).then(c => wrapFunctional(c.default || c))
export const PublicationLanding = () => import('../../components/publication/PublicationLanding.vue' /* webpackChunkName: "components/publication-landing" */).then(c => wrapFunctional(c.default || c))
export const PublicationNavbar = () => import('../../components/publication/PublicationNavbar.vue' /* webpackChunkName: "components/publication-navbar" */).then(c => wrapFunctional(c.default || c))
export const PublicationPostViewer = () => import('../../components/publication/PublicationPostViewer.vue' /* webpackChunkName: "components/publication-post-viewer" */).then(c => wrapFunctional(c.default || c))
export const PublicationSummary = () => import('../../components/publication/PublicationSummary.vue' /* webpackChunkName: "components/publication-summary" */).then(c => wrapFunctional(c.default || c))
export const PublicationTabs = () => import('../../components/publication/PublicationTabs.vue' /* webpackChunkName: "components/publication-tabs" */).then(c => wrapFunctional(c.default || c))
export const PublicationCalculatorContainer = () => import('../../components/publication-settings/PublicationCalculatorContainer.vue' /* webpackChunkName: "components/publication-calculator-container" */).then(c => wrapFunctional(c.default || c))
export const PublicationContactsFormByEmails = () => import('../../components/publication-settings/PublicationContactsFormByEmails.vue' /* webpackChunkName: "components/publication-contacts-form-by-emails" */).then(c => wrapFunctional(c.default || c))
export const PublicationContactsFormByFile = () => import('../../components/publication-settings/PublicationContactsFormByFile.vue' /* webpackChunkName: "components/publication-contacts-form-by-file" */).then(c => wrapFunctional(c.default || c))
export const PublicationCustomiseForm = () => import('../../components/publication-settings/PublicationCustomiseForm.vue' /* webpackChunkName: "components/publication-customise-form" */).then(c => wrapFunctional(c.default || c))
export const PublicationDesignForm = () => import('../../components/publication-settings/PublicationDesignForm.vue' /* webpackChunkName: "components/publication-design-form" */).then(c => wrapFunctional(c.default || c))
export const PublicationFontForm = () => import('../../components/publication-settings/PublicationFontForm.vue' /* webpackChunkName: "components/publication-font-form" */).then(c => wrapFunctional(c.default || c))
export const PublicationInformationsForm = () => import('../../components/publication-settings/PublicationInformationsForm.vue' /* webpackChunkName: "components/publication-informations-form" */).then(c => wrapFunctional(c.default || c))
export const PublicationInformationsFormAbout = () => import('../../components/publication-settings/PublicationInformationsFormAbout.vue' /* webpackChunkName: "components/publication-informations-form-about" */).then(c => wrapFunctional(c.default || c))
export const PublicationInformationsFormCategory = () => import('../../components/publication-settings/PublicationInformationsFormCategory.vue' /* webpackChunkName: "components/publication-informations-form-category" */).then(c => wrapFunctional(c.default || c))
export const PublicationRecommendForm = () => import('../../components/publication-settings/PublicationRecommendForm.vue' /* webpackChunkName: "components/publication-recommend-form" */).then(c => wrapFunctional(c.default || c))
export const PublicationReferralForm = () => import('../../components/publication-settings/PublicationReferralForm.vue' /* webpackChunkName: "components/publication-referral-form" */).then(c => wrapFunctional(c.default || c))
export const PublicationSubscriptionEstimate = () => import('../../components/publication-settings/PublicationSubscriptionEstimate.vue' /* webpackChunkName: "components/publication-subscription-estimate" */).then(c => wrapFunctional(c.default || c))
export const PublicationSubscriptionEstimateCalculator = () => import('../../components/publication-settings/PublicationSubscriptionEstimateCalculator.vue' /* webpackChunkName: "components/publication-subscription-estimate-calculator" */).then(c => wrapFunctional(c.default || c))
export const PublicationSubscriptionEstimateSidebar = () => import('../../components/publication-settings/PublicationSubscriptionEstimateSidebar.vue' /* webpackChunkName: "components/publication-subscription-estimate-sidebar" */).then(c => wrapFunctional(c.default || c))
export const PublicationSubscriptionFree = () => import('../../components/publication-settings/PublicationSubscriptionFree.vue' /* webpackChunkName: "components/publication-subscription-free" */).then(c => wrapFunctional(c.default || c))
export const PublicationSubscriptionPaid = () => import('../../components/publication-settings/PublicationSubscriptionPaid.vue' /* webpackChunkName: "components/publication-subscription-paid" */).then(c => wrapFunctional(c.default || c))
export const PublicationSubscriptionPremium = () => import('../../components/publication-settings/PublicationSubscriptionPremium.vue' /* webpackChunkName: "components/publication-subscription-premium" */).then(c => wrapFunctional(c.default || c))
export const SearchAutoCompleteItem = () => import('../../components/search/SearchAutoCompleteItem.vue' /* webpackChunkName: "components/search-auto-complete-item" */).then(c => wrapFunctional(c.default || c))
export const SearchHeader = () => import('../../components/search/SearchHeader.vue' /* webpackChunkName: "components/search-header" */).then(c => wrapFunctional(c.default || c))
export const AnswerPopup = () => import('../../components/shared/AnswerPopup.vue' /* webpackChunkName: "components/answer-popup" */).then(c => wrapFunctional(c.default || c))
export const KAlert = () => import('../../components/shared/KAlert.vue' /* webpackChunkName: "components/k-alert" */).then(c => wrapFunctional(c.default || c))
export const Lottie = () => import('../../components/shared/Lottie.vue' /* webpackChunkName: "components/lottie" */).then(c => wrapFunctional(c.default || c))
export const PostPublishedPrompt = () => import('../../components/shared/PostPublishedPrompt.vue' /* webpackChunkName: "components/post-published-prompt" */).then(c => wrapFunctional(c.default || c))
export const SideNav = () => import('../../components/shared/SideNav.vue' /* webpackChunkName: "components/side-nav" */).then(c => wrapFunctional(c.default || c))
export const DetailsPost = () => import('../../components/stats/DetailsPost.vue' /* webpackChunkName: "components/details-post" */).then(c => wrapFunctional(c.default || c))
export const LineGraph = () => import('../../components/stats/LineGraph.vue' /* webpackChunkName: "components/line-graph" */).then(c => wrapFunctional(c.default || c))
export const PercentsDifference = () => import('../../components/stats/PercentsDifference.vue' /* webpackChunkName: "components/percents-difference" */).then(c => wrapFunctional(c.default || c))
export const Period = () => import('../../components/stats/Period.vue' /* webpackChunkName: "components/period" */).then(c => wrapFunctional(c.default || c))
export const StatsDashboardPublication = () => import('../../components/stats/StatsDashboardPublication.vue' /* webpackChunkName: "components/stats-dashboard-publication" */).then(c => wrapFunctional(c.default || c))
export const StatsPost = () => import('../../components/stats/StatsPost.vue' /* webpackChunkName: "components/stats-post" */).then(c => wrapFunctional(c.default || c))
export const SubscriberNumber = () => import('../../components/stats/SubscriberNumber.vue' /* webpackChunkName: "components/subscriber-number" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionGraph = () => import('../../components/stats/SubscriptionGraph.vue' /* webpackChunkName: "components/subscription-graph" */).then(c => wrapFunctional(c.default || c))
export const PageviewsGraph = () => import('../../components/stats/pageviews-graph.vue' /* webpackChunkName: "components/pageviews-graph" */).then(c => wrapFunctional(c.default || c))
export const RevenuesGraph = () => import('../../components/stats/revenues-graph.vue' /* webpackChunkName: "components/revenues-graph" */).then(c => wrapFunctional(c.default || c))
export const StudioCarousel = () => import('../../components/studio/StudioCarousel.vue' /* webpackChunkName: "components/studio-carousel" */).then(c => wrapFunctional(c.default || c))
export const StudioFaq = () => import('../../components/studio/StudioFaq.vue' /* webpackChunkName: "components/studio-faq" */).then(c => wrapFunctional(c.default || c))
export const StudioHeader = () => import('../../components/studio/StudioHeader.vue' /* webpackChunkName: "components/studio-header" */).then(c => wrapFunctional(c.default || c))
export const StudioJoin = () => import('../../components/studio/StudioJoin.vue' /* webpackChunkName: "components/studio-join" */).then(c => wrapFunctional(c.default || c))
export const StudioToApply = () => import('../../components/studio/StudioToApply.vue' /* webpackChunkName: "components/studio-to-apply" */).then(c => wrapFunctional(c.default || c))
export const InfinitScroll = () => import('../../components/subscribers/InfinitScroll.vue' /* webpackChunkName: "components/infinit-scroll" */).then(c => wrapFunctional(c.default || c))
export const SubscriberCard = () => import('../../components/subscribers/SubscriberCard.vue' /* webpackChunkName: "components/subscriber-card" */).then(c => wrapFunctional(c.default || c))
export const SubscriberReferralCard = () => import('../../components/subscribers/SubscriberReferralCard.vue' /* webpackChunkName: "components/subscriber-referral-card" */).then(c => wrapFunctional(c.default || c))
export const KTable = () => import('../../components/table/KTable.vue' /* webpackChunkName: "components/k-table" */).then(c => wrapFunctional(c.default || c))
export const KTableHeader = () => import('../../components/table/KTableHeader.vue' /* webpackChunkName: "components/k-table-header" */).then(c => wrapFunctional(c.default || c))
export const KTableRows = () => import('../../components/table/KTableRows.vue' /* webpackChunkName: "components/k-table-rows" */).then(c => wrapFunctional(c.default || c))
export const DashboardTemplate = () => import('../../components/templates/DashboardTemplate.vue' /* webpackChunkName: "components/dashboard-template" */).then(c => wrapFunctional(c.default || c))
export const SignTemplate = () => import('../../components/templates/SignTemplate.vue' /* webpackChunkName: "components/sign-template" */).then(c => wrapFunctional(c.default || c))
export const CardPost = () => import('../../components/trending/CardPost.vue' /* webpackChunkName: "components/card-post" */).then(c => wrapFunctional(c.default || c))
export const CarouselPublicationCard = () => import('../../components/category/card/CarouselPublicationCard.vue' /* webpackChunkName: "components/carousel-publication-card" */).then(c => wrapFunctional(c.default || c))
export const CategoryCarouselNewsletter = () => import('../../components/category/section/CategoryCarouselNewsletter.vue' /* webpackChunkName: "components/category-carousel-newsletter" */).then(c => wrapFunctional(c.default || c))
export const CategoryTabs = () => import('../../components/category/section/CategoryTabs.vue' /* webpackChunkName: "components/category-tabs" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/editor/components/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/editor/components/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const LinkForm = () => import('../../components/editor/components/LinkForm.vue' /* webpackChunkName: "components/link-form" */).then(c => wrapFunctional(c.default || c))
export const PaywallSeparator = () => import('../../components/editor/components/PaywallSeparator.vue' /* webpackChunkName: "components/paywall-separator" */).then(c => wrapFunctional(c.default || c))
export const OfferList = () => import('../../components/form/offer/OfferList.vue' /* webpackChunkName: "components/offer-list" */).then(c => wrapFunctional(c.default || c))
export const RecommendDrawer = () => import('../../components/form/recommend/RecommendDrawer.vue' /* webpackChunkName: "components/recommend-drawer" */).then(c => wrapFunctional(c.default || c))
export const RecommendForm = () => import('../../components/form/recommend/RecommendForm.vue' /* webpackChunkName: "components/recommend-form" */).then(c => wrapFunctional(c.default || c))
export const AuthorCard = () => import('../../components/landing/card/AuthorCard.vue' /* webpackChunkName: "components/author-card" */).then(c => wrapFunctional(c.default || c))
export const CarouselAuthorCard = () => import('../../components/landing/card/CarouselAuthorCard.vue' /* webpackChunkName: "components/carousel-author-card" */).then(c => wrapFunctional(c.default || c))
export const NewsletterCard = () => import('../../components/landing/card/NewsletterCard.vue' /* webpackChunkName: "components/newsletter-card" */).then(c => wrapFunctional(c.default || c))
export const NewsletterCounter = () => import('../../components/landing/card/NewsletterCounter.vue' /* webpackChunkName: "components/newsletter-counter" */).then(c => wrapFunctional(c.default || c))
export const PostCard = () => import('../../components/landing/card/PostCard.vue' /* webpackChunkName: "components/post-card" */).then(c => wrapFunctional(c.default || c))
export const RequestCategory = () => import('../../components/landing/categories/RequestCategory.vue' /* webpackChunkName: "components/request-category" */).then(c => wrapFunctional(c.default || c))
export const LandingSectionCarouselAuthors = () => import('../../components/landing/sections/LandingSectionCarouselAuthors.vue' /* webpackChunkName: "components/landing-section-carousel-authors" */).then(c => wrapFunctional(c.default || c))
export const LandingSectionCategory = () => import('../../components/landing/sections/LandingSectionCategory.vue' /* webpackChunkName: "components/landing-section-category" */).then(c => wrapFunctional(c.default || c))
export const LandingSectionReco = () => import('../../components/landing/sections/LandingSectionReco.vue' /* webpackChunkName: "components/landing-section-reco" */).then(c => wrapFunctional(c.default || c))
export const LandingSectionTopMeta = () => import('../../components/landing/sections/LandingSectionTopMeta.vue' /* webpackChunkName: "components/landing-section-top-meta" */).then(c => wrapFunctional(c.default || c))
export const LandingSectionTopPosts = () => import('../../components/landing/sections/LandingSectionTopPosts.vue' /* webpackChunkName: "components/landing-section-top-posts" */).then(c => wrapFunctional(c.default || c))
export const TipTapBlockButton = () => import('../../components/editor/components/tiptap/TipTapBlockButton.vue' /* webpackChunkName: "components/tip-tap-block-button" */).then(c => wrapFunctional(c.default || c))
export const TipTapBubbleMenu = () => import('../../components/editor/components/tiptap/TipTapBubbleMenu.vue' /* webpackChunkName: "components/tip-tap-bubble-menu" */).then(c => wrapFunctional(c.default || c))
export const TipTapButton = () => import('../../components/editor/components/tiptap/TipTapButton.vue' /* webpackChunkName: "components/tip-tap-button" */).then(c => wrapFunctional(c.default || c))
export const TipTapColorButton = () => import('../../components/editor/components/tiptap/TipTapColorButton.vue' /* webpackChunkName: "components/tip-tap-color-button" */).then(c => wrapFunctional(c.default || c))
export const TipTapColorsButton = () => import('../../components/editor/components/tiptap/TipTapColorsButton.vue' /* webpackChunkName: "components/tip-tap-colors-button" */).then(c => wrapFunctional(c.default || c))
export const TipTapCustomButton = () => import('../../components/editor/components/tiptap/TipTapCustomButton.vue' /* webpackChunkName: "components/tip-tap-custom-button" */).then(c => wrapFunctional(c.default || c))
export const TipTapEmbedElement = () => import('../../components/editor/components/tiptap/TipTapEmbedElement.vue' /* webpackChunkName: "components/tip-tap-embed-element" */).then(c => wrapFunctional(c.default || c))
export const TipTapFontFamilyButton = () => import('../../components/editor/components/tiptap/TipTapFontFamilyButton.vue' /* webpackChunkName: "components/tip-tap-font-family-button" */).then(c => wrapFunctional(c.default || c))
export const TipTapHeadingButton = () => import('../../components/editor/components/tiptap/TipTapHeadingButton.vue' /* webpackChunkName: "components/tip-tap-heading-button" */).then(c => wrapFunctional(c.default || c))
export const TipTapHoveringToolbar = () => import('../../components/editor/components/tiptap/TipTapHoveringToolbar.vue' /* webpackChunkName: "components/tip-tap-hovering-toolbar" */).then(c => wrapFunctional(c.default || c))
export const TipTapImageElement = () => import('../../components/editor/components/tiptap/TipTapImageElement.vue' /* webpackChunkName: "components/tip-tap-image-element" */).then(c => wrapFunctional(c.default || c))
export const TipTapLinkButton = () => import('../../components/editor/components/tiptap/TipTapLinkButton.vue' /* webpackChunkName: "components/tip-tap-link-button" */).then(c => wrapFunctional(c.default || c))
export const TipTapLinkElement = () => import('../../components/editor/components/tiptap/TipTapLinkElement.vue' /* webpackChunkName: "components/tip-tap-link-element" */).then(c => wrapFunctional(c.default || c))
export const TipTapMarkButton = () => import('../../components/editor/components/tiptap/TipTapMarkButton.vue' /* webpackChunkName: "components/tip-tap-mark-button" */).then(c => wrapFunctional(c.default || c))
export const TipTapUndoRedoButton = () => import('../../components/editor/components/tiptap/TipTapUndoRedoButton.vue' /* webpackChunkName: "components/tip-tap-undo-redo-button" */).then(c => wrapFunctional(c.default || c))
export const TipTapButtonDrawer = () => import('../../components/editor/components/tiptap/drawer/TipTapButtonDrawer.vue' /* webpackChunkName: "components/tip-tap-button-drawer" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
