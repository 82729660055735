import { render, staticRenderFns } from "./RecommendDrawer.vue?vue&type=template&id=62fd4ae0&scoped=true&"
import script from "./RecommendDrawer.vue?vue&type=script&lang=ts&"
export * from "./RecommendDrawer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62fd4ae0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KButton: require('/tmp/build_6043b71c/apps/web/components/buttons/KButton.vue').default,RecommendForm: require('/tmp/build_6043b71c/apps/web/components/form/recommend/RecommendForm.vue').default})
