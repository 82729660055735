import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a8c2e022 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _0ef52732 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _69d4bcaa = () => interopDefault(import('../pages/authors.vue' /* webpackChunkName: "pages/authors" */))
const _cf73551a = () => interopDefault(import('../pages/cgu.vue' /* webpackChunkName: "pages/cgu" */))
const _d603e46a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _400b7314 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _b105958a = () => interopDefault(import('../pages/embed.vue' /* webpackChunkName: "pages/embed" */))
const _2c839578 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _26d83331 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _92f85e18 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _613e945a = () => interopDefault(import('../pages/register/1.vue' /* webpackChunkName: "pages/register/1" */))
const _61226558 = () => interopDefault(import('../pages/register/2.vue' /* webpackChunkName: "pages/register/2" */))
const _61063656 = () => interopDefault(import('../pages/register/3.vue' /* webpackChunkName: "pages/register/3" */))
const _60ea0754 = () => interopDefault(import('../pages/register/4.vue' /* webpackChunkName: "pages/register/4" */))
const _60cdd852 = () => interopDefault(import('../pages/register/5.vue' /* webpackChunkName: "pages/register/5" */))
const _0075197a = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _a321f9ae = () => interopDefault(import('../pages/password.vue' /* webpackChunkName: "pages/password" */))
const _73e65535 = () => interopDefault(import('../pages/posts.vue' /* webpackChunkName: "pages/posts" */))
const _2eed4010 = () => interopDefault(import('../pages/posts/index.vue' /* webpackChunkName: "pages/posts/index" */))
const _3b41bee3 = () => interopDefault(import('../pages/posts/_id/index.vue' /* webpackChunkName: "pages/posts/_id/index" */))
const _55987844 = () => interopDefault(import('../pages/posts/_id/_slug/index.vue' /* webpackChunkName: "pages/posts/_id/_slug/index" */))
const _48de9d8b = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _acf8e064 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _796002a9 = () => interopDefault(import('../pages/profile/account.vue' /* webpackChunkName: "pages/profile/account" */))
const _d7e6e552 = () => interopDefault(import('../pages/profile/settings.vue' /* webpackChunkName: "pages/profile/settings" */))
const _0ed8c788 = () => interopDefault(import('../pages/publication-terms.vue' /* webpackChunkName: "pages/publication-terms" */))
const _9129e98e = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _0c4dbf4e = () => interopDefault(import('../pages/resubscribe.vue' /* webpackChunkName: "pages/resubscribe" */))
const _3ed84264 = () => interopDefault(import('../pages/studio.vue' /* webpackChunkName: "pages/studio" */))
const _cd1f92d8 = () => interopDefault(import('../pages/why-kessel.vue' /* webpackChunkName: "pages/why-kessel" */))
const _a94f5080 = () => interopDefault(import('../pages/unsubscribe/email.vue' /* webpackChunkName: "pages/unsubscribe/email" */))
const _eff85d66 = () => interopDefault(import('../pages/publication/create.vue' /* webpackChunkName: "pages/publication/create" */))
const _2d2f6a10 = () => interopDefault(import('../pages/publication/create/index.vue' /* webpackChunkName: "pages/publication/create/index" */))
const _d7c58622 = () => interopDefault(import('../pages/publication/create/1.vue' /* webpackChunkName: "pages/publication/create/1" */))
const _d7a95720 = () => interopDefault(import('../pages/publication/create/2.vue' /* webpackChunkName: "pages/publication/create/2" */))
const _31e5c847 = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _006ac74e = () => interopDefault(import('../pages/category/_slug/_tab.vue' /* webpackChunkName: "pages/category/_slug/_tab" */))
const _4bf39e39 = () => interopDefault(import('../pages/publication/_id.vue' /* webpackChunkName: "pages/publication/_id" */))
const _3d54d5fc = () => interopDefault(import('../pages/publication/_id/index.vue' /* webpackChunkName: "pages/publication/_id/index" */))
const _19f93990 = () => interopDefault(import('../pages/publication/_id/drafts.vue' /* webpackChunkName: "pages/publication/_id/drafts" */))
const _34367f06 = () => interopDefault(import('../pages/publication/_id/posts.vue' /* webpackChunkName: "pages/publication/_id/posts" */))
const _3aba1aa0 = () => interopDefault(import('../pages/publication/_id/referrals.vue' /* webpackChunkName: "pages/publication/_id/referrals" */))
const _42827069 = () => interopDefault(import('../pages/publication/_id/settings.vue' /* webpackChunkName: "pages/publication/_id/settings" */))
const _5929442c = () => interopDefault(import('../pages/publication/_id/settings/index.vue' /* webpackChunkName: "pages/publication/_id/settings/index" */))
const _05a22069 = () => interopDefault(import('../pages/publication/_id/settings/contacts.vue' /* webpackChunkName: "pages/publication/_id/settings/contacts" */))
const _a6622c78 = () => interopDefault(import('../pages/publication/_id/settings/customise.vue' /* webpackChunkName: "pages/publication/_id/settings/customise" */))
const _bf550858 = () => interopDefault(import('../pages/publication/_id/settings/design.vue' /* webpackChunkName: "pages/publication/_id/settings/design" */))
const _20c3e23b = () => interopDefault(import('../pages/publication/_id/settings/import.vue' /* webpackChunkName: "pages/publication/_id/settings/import" */))
const _e3111910 = () => interopDefault(import('../pages/publication/_id/settings/options.vue' /* webpackChunkName: "pages/publication/_id/settings/options" */))
const _12ef7616 = () => interopDefault(import('../pages/publication/_id/settings/recommend.vue' /* webpackChunkName: "pages/publication/_id/settings/recommend" */))
const _347086b3 = () => interopDefault(import('../pages/publication/_id/settings/referral.vue' /* webpackChunkName: "pages/publication/_id/settings/referral" */))
const _17c1f533 = () => interopDefault(import('../pages/publication/_id/settings/subscription.vue' /* webpackChunkName: "pages/publication/_id/settings/subscription" */))
const _1c30796e = () => interopDefault(import('../pages/publication/_id/stats.vue' /* webpackChunkName: "pages/publication/_id/stats" */))
const _071a3435 = () => interopDefault(import('../pages/publication/_id/subscribers.vue' /* webpackChunkName: "pages/publication/_id/subscribers" */))
const _19a3b689 = () => interopDefault(import('../pages/publication/_id/write.vue' /* webpackChunkName: "pages/publication/_id/write" */))
const _69b55a28 = () => interopDefault(import('../pages/publication/_id/templates/_templateId.vue' /* webpackChunkName: "pages/publication/_id/templates/_templateId" */))
const _04e2f082 = () => interopDefault(import('../pages/search/_tab.vue' /* webpackChunkName: "pages/search/_tab" */))
const _0e2961ba = () => interopDefault(import('../pages/search/_tab/_slug.vue' /* webpackChunkName: "pages/search/_tab/_slug" */))
const _69532a98 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _a8c2e022,
    children: [{
      path: "",
      component: _0ef52732,
      name: "about___fr"
    }]
  }, {
    path: "/auteurs",
    component: _69d4bcaa,
    name: "authors___fr"
  }, {
    path: "/cgu",
    component: _cf73551a,
    name: "cgu___fr"
  }, {
    path: "/connexion",
    component: _d603e46a,
    name: "login___fr"
  }, {
    path: "/dashboard",
    component: _400b7314,
    name: "dashboard___fr"
  }, {
    path: "/embed",
    component: _b105958a,
    name: "embed___fr"
  }, {
    path: "/faq",
    component: _2c839578,
    name: "faq___fr"
  }, {
    path: "/inscription",
    component: _26d83331,
    children: [{
      path: "",
      component: _92f85e18,
      name: "register___fr"
    }, {
      path: "1",
      component: _613e945a,
      name: "register-1___fr"
    }, {
      path: "2",
      component: _61226558,
      name: "register-2___fr"
    }, {
      path: "3",
      component: _61063656,
      name: "register-3___fr"
    }, {
      path: "4",
      component: _60ea0754,
      name: "register-4___fr"
    }, {
      path: "5",
      component: _60cdd852,
      name: "register-5___fr"
    }]
  }, {
    path: "/legal-notice",
    component: _0075197a,
    name: "legal-notice___fr"
  }, {
    path: "/password",
    component: _a321f9ae,
    name: "password___fr"
  }, {
    path: "/posts",
    component: _73e65535,
    children: [{
      path: "",
      component: _2eed4010,
      name: "posts___fr"
    }, {
      path: ":id",
      component: _3b41bee3,
      name: "posts-id___fr"
    }, {
      path: ":id/:slug",
      component: _55987844,
      name: "posts-id-slug___fr"
    }]
  }, {
    path: "/profile",
    component: _48de9d8b,
    children: [{
      path: "",
      component: _acf8e064,
      name: "profile___fr"
    }, {
      path: "account",
      component: _796002a9,
      name: "profile-account___fr"
    }, {
      path: "settings",
      component: _d7e6e552,
      name: "profile-settings___fr"
    }]
  }, {
    path: "/publication-terms",
    component: _0ed8c788,
    name: "publication-terms___fr"
  }, {
    path: "/recherche",
    component: _9129e98e,
    name: "search___fr"
  }, {
    path: "/reinscription",
    component: _0c4dbf4e,
    name: "resubscribe___fr"
  }, {
    path: "/studio",
    component: _3ed84264,
    name: "studio___fr"
  }, {
    path: "/why-kessel",
    component: _cd1f92d8,
    name: "why-kessel___fr"
  }, {
    path: "/desinscription/email",
    component: _a94f5080,
    name: "unsubscribe-email___fr"
  }, {
    path: "/publication/create",
    component: _eff85d66,
    children: [{
      path: "",
      component: _2d2f6a10,
      name: "publication-create___fr"
    }, {
      path: "1",
      component: _d7c58622,
      name: "publication-create-1___fr"
    }, {
      path: "2",
      component: _d7a95720,
      name: "publication-create-2___fr"
    }]
  }, {
    path: "/categorie/:slug",
    component: _31e5c847,
    name: "category-slug___fr",
    children: [{
      path: ":tab?",
      component: _006ac74e,
      name: "category-slug-tab___fr"
    }]
  }, {
    path: "/publication/:id?",
    component: _4bf39e39,
    children: [{
      path: "",
      component: _3d54d5fc,
      name: "publication-id___fr"
    }, {
      path: "drafts",
      component: _19f93990,
      name: "publication-id-drafts___fr"
    }, {
      path: "posts",
      component: _34367f06,
      name: "publication-id-posts___fr"
    }, {
      path: "referrals",
      component: _3aba1aa0,
      name: "publication-id-referrals___fr"
    }, {
      path: "settings",
      component: _42827069,
      children: [{
        path: "",
        component: _5929442c,
        name: "publication-id-settings___fr"
      }, {
        path: "contacts",
        component: _05a22069,
        name: "publication-id-settings-contacts___fr"
      }, {
        path: "customise",
        component: _a6622c78,
        name: "publication-id-settings-customise___fr"
      }, {
        path: "design",
        component: _bf550858,
        name: "publication-id-settings-design___fr"
      }, {
        path: "import",
        component: _20c3e23b,
        name: "publication-id-settings-import___fr"
      }, {
        path: "options",
        component: _e3111910,
        name: "publication-id-settings-options___fr"
      }, {
        path: "recommend",
        component: _12ef7616,
        name: "publication-id-settings-recommend___fr"
      }, {
        path: "referral",
        component: _347086b3,
        name: "publication-id-settings-referral___fr"
      }, {
        path: "subscription",
        component: _17c1f533,
        name: "publication-id-settings-subscription___fr"
      }]
    }, {
      path: "stats",
      component: _1c30796e,
      name: "publication-id-stats___fr"
    }, {
      path: "subscribers",
      component: _071a3435,
      name: "publication-id-subscribers___fr"
    }, {
      path: "write",
      component: _19a3b689,
      name: "publication-id-write___fr"
    }, {
      path: "templates/:templateId?",
      component: _69b55a28,
      name: "publication-id-templates-templateId___fr"
    }]
  }, {
    path: "/recherche/:tab",
    component: _04e2f082,
    name: "search-tab___fr",
    children: [{
      path: ":slug?",
      component: _0e2961ba,
      name: "search-tab-slug___fr"
    }]
  }, {
    path: "/",
    component: _69532a98,
    name: "index___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
