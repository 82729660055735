/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AccountStatus */
export enum KAccountStatus {
  Unfinished = 'unfinished',
  Enabled = 'enabled',
  Disabled = 'disabled',
}

/** ArticleRead */
export interface KArticleRead {
  /** Id */
  id: string
  /** Title */
  title?: string | null
  /** Description */
  description?: string | null
  cropped_image?: KImageRead | null
  cropped_header_image?: KImageRead | null
  /** Newsletter Id */
  newsletter_id?: string | null
  /** Likes Count */
  likes_count?: number | null
  /** Reading Time */
  reading_time?: number | null
  /** Published At */
  published_at?: string | null
  /** Republished At */
  republished_at?: string | null
  /** Created At */
  created_at?: string | null
  /** Last Updated */
  last_updated?: string | null
  /** Subdomain */
  subdomain?: string | null
  /** Visibility Level */
  visibility_level?: number | null
  /**
   * Pinned
   * @default false
   */
  pinned?: boolean
  /**
   * Mail
   * @default false
   */
  mail?: boolean
  /** Body */
  body?: string | null
}

/** ArticleReadLight */
export interface KArticleReadLight {
  /** Id */
  id: string
  /** Title */
  title?: string | null
  /** Description */
  description?: string | null
  cropped_image?: KImageRead | null
  cropped_header_image?: KImageRead | null
  /** Newsletter Id */
  newsletter_id?: string | null
  /** Likes Count */
  likes_count?: number | null
  /** Reading Time */
  reading_time?: number | null
  /** Published At */
  published_at?: string | null
  /** Republished At */
  republished_at?: string | null
  /** Created At */
  created_at?: string | null
  /** Last Updated */
  last_updated?: string | null
  /** Subdomain */
  subdomain?: string | null
  /** Visibility Level */
  visibility_level?: number | null
  /**
   * Pinned
   * @default false
   */
  pinned?: boolean
  /**
   * Mail
   * @default false
   */
  mail?: boolean
}

/** ArticleReadOwner */
export interface KArticleReadOwner {
  /** Id */
  id: string
  /** Title */
  title?: string | null
  /** Description */
  description?: string | null
  cropped_image?: KImageRead | null
  cropped_header_image?: KImageRead | null
  /** Newsletter Id */
  newsletter_id?: string | null
  /** Likes Count */
  likes_count?: number | null
  /** Reading Time */
  reading_time?: number | null
  /** Published At */
  published_at?: string | null
  /** Republished At */
  republished_at?: string | null
  /** Created At */
  created_at?: string | null
  /** Last Updated */
  last_updated?: string | null
  /** Subdomain */
  subdomain?: string | null
  /** Visibility Level */
  visibility_level?: number | null
  /**
   * Pinned
   * @default false
   */
  pinned?: boolean
  /**
   * Mail
   * @default false
   */
  mail?: boolean
  /** Title Draft */
  title_draft?: string | null
  /** Description Draft */
  description_draft?: string | null
  cropped_image_draft?: KImageRead | null
  cropped_header_image_draft?: KImageRead | null
  /** Is Draft */
  is_draft?: boolean | null
  /** Scheduled At */
  scheduled_at?: string | null
  /** Body */
  body?: string | null
  /** Body Draft */
  body_draft?: string | null
  /** Paywall Separator Position */
  paywall_separator_position?: number | null
}

/** ArticleStat */
export interface KArticleStat {
  email?: KEmailStat | null
  click?: KEmailClickStat | null
}

/** Body_token_oauth_token_post */
export interface KBodyTokenOauthTokenPost {
  /**
   * Grant Type
   * @pattern password|refresh_token
   */
  grant_type?: string
  /**
   * Username
   * @default ""
   */
  username?: string
  /**
   * Password
   * @default ""
   */
  password?: string
  /**
   * Refresh Token
   * @default ""
   */
  refresh_token?: string
  /**
   * Scope
   * @default ""
   */
  scope?: string
}

/** CategoryRead */
export interface KCategoryRead {
  /** Name */
  name?: string | null
  /** Slug */
  slug: string
}

/** CommentCreate */
export interface KCommentCreate {
  /** Parent Id */
  parent_id: string
  /** Content */
  content: string
}

/** CommentRead */
export interface KCommentRead {
  /** Id */
  id: string
  user: KUserRead
  /** Likes Count */
  likes_count: number
  /**
   * Created At
   * @format date-time
   */
  created_at: string
  /** Content */
  content: string
}

/** CommentUpdate */
export interface KCommentUpdate {
  /** Content */
  content: string
}

/** Currency */
export enum KCurrency {
  Eur = 'eur',
}

/** CustomUnitAmont */
export interface KCustomUnitAmont {
  /**
   * Enabled
   * @default false
   */
  enabled?: boolean
  /** Maximum */
  maximum?: number | null
  /** Minimum */
  minimum?: number | null
  /** Preset */
  preset?: number | null
}

/** EmailClickStat */
export interface KEmailClickStat {
  /**
   * Links
   * @default []
   */
  links?: KLink[]
  /**
   * Total
   * @default 0
   */
  total?: number
}

/** EmailStat */
export interface KEmailStat {
  /**
   * Open
   * @default 0
   */
  open?: number
  /**
   * Send
   * @default 0
   */
  send?: number
  /**
   * Delivery
   * @default 0
   */
  delivery?: number
  /**
   * Hard Bounce
   * @default 0
   */
  hard_bounce?: number
  /**
   * Soft Bounce
   * @default 0
   */
  soft_bounce?: number
  /**
   * Complaint
   * @default 0
   */
  complaint?: number
  /**
   * Click
   * @default 0
   */
  click?: number
  /** Opening Rate */
  opening_rate: number
  /** View count */
  view_count: number
}

/** Feature */
export interface KFeature {
  /** Name */
  name: string
}

/** HTTPValidationError */
export interface KHTTPValidationError {
  /** Detail */
  detail?: KValidationError[]
}

/** ImageRead */
export interface KImageRead {
  /** Filename */
  filename?: string | null
  /** X */
  x?: number | null
  /** Y */
  y?: number | null
  /** Width */
  width?: number | null
  /** Height */
  height?: number | null
}

/** IntervalEnum */
export enum KIntervalEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

/** LikeRead */
export interface KLikeRead {
  /** Parent Id */
  parent_id: string
  /** User Id */
  user_id: string
  /**
   * Created At
   * @format date-time
   */
  created_at: string
}

/** Link */
export interface KLink {
  /** Url */
  url: string
  /**
   * Count
   * @default 0
   */
  count?: number
  /**
   * Percentage
   * @default 0
   */
  percentage?: number
}

/** NewsletterLight */
export interface KNewsletterLight {
  /** Title */
  title: string
  /** Description */
  description?: string | null
  cropped_banner_picture?: KImageRead | null
  /** Color Primary */
  color_primary?: string | null
  /** Color Secondary */
  color_secondary?: string | null
  author?: KUserRead | null
  subdomain?: KSubdomainRead | null
}

/** NewsletterRead */
export interface KNewsletterRead {
  /** Title */
  title: string
  /** Description */
  description?: string | null
  cropped_banner_picture?: KImageRead | null
  /** Color Primary */
  color_primary?: string | null
  /** Color Secondary */
  color_secondary?: string | null
  author?: KUserRead | null
  subdomain?: KSubdomainRead | null
  /** Id */
  id: string
  cropped_about_picture?: KImageRead | null
  /**
   * Owners
   * @default []
   */
  owners?: KUserRead[]
  /** Total Posts */
  total_posts?: number | null
  /** Payment Enabled */
  payment_enabled?: boolean | null
  /** Comment Enabled */
  comment_enabled?: boolean | null
  /** About Enabled */
  about_enabled?: boolean | null
  /** Recommendation Enabled */
  recommendation_enabled?: boolean | null
  /** Default Title Font */
  default_title_font?: string | null
  /** Default Text Font */
  default_text_font?: string | null
}

/** NewsletterReadOwner */
export interface KNewsletterReadOwner {
  /** Title */
  title: string
  /** Description */
  description?: string | null
  cropped_banner_picture?: KImageRead | null
  /** Color Primary */
  color_primary?: string | null
  /** Color Secondary */
  color_secondary?: string | null
  author?: KUserRead | null
  subdomain?: KSubdomainRead | null
  /** Id */
  id: string
  cropped_about_picture?: KImageRead | null
  /**
   * Owners
   * @default []
   */
  owners?: KUserRead[]
  /** Total Posts */
  total_posts?: number | null
  /** Payment Enabled */
  payment_enabled?: boolean | null
  /** Comment Enabled */
  comment_enabled?: boolean | null
  /** About Enabled */
  about_enabled?: boolean | null
  /** Recommendation Enabled */
  recommendation_enabled?: boolean | null
  /** Default Title Font */
  default_title_font?: string | null
  /** Default Text Font */
  default_text_font?: string | null
  /** Total Drafts */
  total_drafts?: number | null
  /** Total Scheduled */
  total_scheduled?: number | null
  /** Total Subscribers */
  total_subscribers?: number | null
  /** Total Unsubscribed */
  total_unsubscribed?: number | null
  /**
   * Categories
   * @default []
   */
  categories?: KCategoryRead[]
  /** Stripe Id */
  stripe_id?: number | null
  stripe?: KStripeReadOwner | null
  /** Mail Sender Name */
  mail_sender_name?: string | null
  /** Can Import */
  can_import?: boolean | null
}

/** OpeningRateStat */
export interface KOpeningRateStat {
  /**
   * Opening Rate
   * @default 0
   */
  opening_rate?: number
}

/** Page[ArticleReadLight] */
export interface KPageArticleReadLight {
  /** Items */
  items: KArticleReadLight[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[ArticleReadOwner] */
export interface KPageArticleReadOwner {
  /** Items */
  items: KArticleReadOwner[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[CategoryRead] */
export interface KPageCategoryRead {
  /** Items */
  items: KCategoryRead[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[CommentRead] */
export interface KPageCommentRead {
  /** Items */
  items: KCommentRead[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[NewsletterReadOwner] */
export interface KPageNewsletterReadOwner {
  /** Items */
  items: KNewsletterReadOwner[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[NewsletterRead] */
export interface KPageNewsletterRead {
  /** Items */
  items: KNewsletterRead[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[StripeProduct] */
export interface KPageStripeProduct {
  /** Items */
  items: KStripeProduct[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[SubscriptionReadLight] */
export interface KPageSubscriptionReadLight {
  /** Items */
  items: KSubscriptionReadLight[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[SubscriptionRead] */
export interface KPageSubscriptionRead {
  /** Items */
  items: KSubscriptionRead[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** PaymentStatus */
export enum KPaymentStatus {
  UNKNOWN = 'UNKNOWN',
  ACTIVE = 'ACTIVE',
  INCOMPLETE = 'INCOMPLETE',
  PAST_DUE = 'PAST_DUE',
  CANCELLATION = 'CANCELLATION',
  CANCELED = 'CANCELED',
}

/** PlanType */
export enum KPlanType {
  Starter = 'starter',
  Standard = 'standard',
  Premium = 'premium',
  Gold = 'gold',
}

/** PriceMetaData */
export interface KPriceMetaData {
  /** Kessel Price Label */
  kessel_price_label?: string | null
  /** Kessel Price Description */
  kessel_price_description?: string | null
  /** Kessel Publication Id */
  kessel_publication_id?: string | null
  /** Kessel Subscription Level */
  kessel_subscription_level?: number | null
}

/** PriceType */
export enum KPriceType {
  OneTime = 'one_time',
  Recurring = 'recurring',
}

/** ProductMetaData */
export interface KProductMetaData {
  kessel_type?: KPlanType | null
  /** Kessel Publication Id */
  kessel_publication_id?: string | null
  /**
   * Kessel Trial Days
   * @default 0
   */
  kessel_trial_days?: number | null
}

/** Recurring */
export interface KRecurring {
  interval: KIntervalEnum
  /** Interval Count */
  interval_count: number
  /**
   * Trial Period Days
   * @default 0
   */
  trial_period_days?: number | null
}

/** RegisterForm */
export interface KRegisterForm {
  /**
   * Username
   * @format email
   */
  username: string
  /** Password */
  password?: string | null
  /**
   * Cgu Accepted
   * @default false
   */
  cgu_accepted?: boolean
}

/** StripeOfferRead */
export interface KStripeOfferRead {
  /** Products */
  products: KStripeProduct[]
}

/** StripePrice */
export interface KStripePrice {
  /** Id */
  id: string
  /** Active */
  active?: boolean | null
  type: KPriceType
  /** Product */
  product: string
  metadata: KPriceMetaData
  /** Unit Amount */
  unit_amount: number
  custom_unit_amount?: KCustomUnitAmont | null
  recurring?: KRecurring | null
  /** Nickname */
  nickname?: string | null
  /** @default "eur" */
  currency?: KCurrency
}

/** StripePriceCreate */
export interface KStripePriceCreate {
  /** Active */
  active?: boolean | null
  /** Unit Amount */
  unit_amount: number
  /** Product */
  product?: string | null
  custom_unit_amount?: KCustomUnitAmont | null
  recurring?: KRecurring | null
  metadata: KPriceMetaData
  /** Nickname */
  nickname?: string | null
  /** @default "eur" */
  currency?: KCurrency
}

/** StripePriceUpdate */
export interface KStripePriceUpdate {
  /** Id */
  id: string
  /** Active */
  active?: boolean | null
  /** Unit Amount */
  unit_amount?: number | null
  metadata?: KPriceMetaData | null
  /** Nickname */
  nickname?: string | null
}

/** StripeProduct */
export interface KStripeProduct {
  /** Id */
  id: string
  /** Active */
  active: boolean
  /** Name */
  name: string
  metadata: KProductMetaData
  /** Default Price */
  default_price?: string | null
  /** Description */
  description?: string | null
  /**
   * Prices
   * @default []
   */
  prices?: KStripePrice[]
  /**
   * Features
   * @default []
   */
  features?: KFeature[]
}

/** StripeProductCreate */
export interface KStripeProductCreate {
  /** Name */
  name: string
  metadata?: KProductMetaData | null
  /**
   * Active
   * @default true
   */
  active?: boolean
  /** Description */
  description?: string | null
  /**
   * Features
   * @default []
   */
  features?: KFeature[]
  /**
   * Prices
   * @default []
   */
  prices?: KStripePriceCreate[]
}

/** StripeProductUpdate */
export interface KStripeProductUpdate {
  /** Default Price */
  default_price?: string | null
  /** Name */
  name?: string | null
  metadata?: KProductMetaData | null
  /** Active */
  active?: boolean | null
  /** Description */
  description?: string | null
  /**
   * Features
   * @default []
   */
  features?: KFeature[]
  /**
   * Prices
   * @default []
   */
  prices?: (KStripePriceUpdate | KStripePriceCreate)[]
}

/** StripeReadOwner */
export interface KStripeReadOwner {
  /**
   * Type
   * @default "express"
   */
  type?: string | null
  status: KAccountStatus
  /** Account Id */
  account_id: string
  /** Payouts Enabled */
  payouts_enabled: boolean
  /** Charges Enabled */
  charges_enabled: boolean
}

/** SubdomainRead */
export interface KSubdomainRead {
  /** Name */
  name: string
}

/** SubscriptionLevelEnum */
export enum KSubscriptionLevelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

/** SubscriptionRead */
export interface KSubscriptionRead {
  /** Id */
  id: string
  payment_status?: KPaymentStatus | null
  subscription_level: KSubscriptionLevelEnum
  /** Newsletter Id */
  newsletter_id: string
  newsletter: KNewsletterLight
  /** User Id */
  user_id: string
  user: KUserRead
  /** Mailing Enabled */
  mailing_enabled?: boolean | null
  /**
   * Created At
   * @format date-time
   */
  created_at: string
}

/** SubscriptionReadLight */
export interface KSubscriptionReadLight {
  /** Id */
  id: string
  payment_status?: KPaymentStatus | null
  subscription_level: KSubscriptionLevelEnum
  /** Newsletter Id */
  newsletter_id: string
  /** User Id */
  user_id: string
  user: KUserRead
  /** Mailing Enabled */
  mailing_enabled?: boolean | null
  /**
   * Created At
   * @format date-time
   */
  created_at: string
}

/** Token */
export interface KToken {
  /** Access Token */
  access_token: string
  /** Refresh Token */
  refresh_token: string | null
  /**
   * Token Type
   * @default "bearer"
   */
  token_type?: string
}

/** UserRead */
export interface KUserRead {
  cropped_picture?: KImageRead | null
  /** First Name */
  first_name?: string | null
  /** Last Name */
  last_name?: string | null
  /** Full Name */
  full_name?: string | null
  /** Verified At */
  verified_at?: string | null
  /** Email */
  email?: string | null
  /** Biography */
  biography?: string | null
  /** Id */
  id: string
  /** Promoted */
  promoted: boolean
}

/** ValidationError */
export interface KValidationError {
  /** Location */
  loc: (string | number)[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

/** FilterEnum */
export enum KSchemasArticleFilterEnum {
  Published = 'published',
  Draft = 'draft',
  Scheduled = 'scheduled',
  MailSent = 'mail_sent',
  Pinned = 'pinned',
}

/** SortEnum */
export enum KSchemasArticleSortEnum {
  LastUpdated = 'last_updated',
  PublishedAt = 'published_at',
  ScheduledAt = 'scheduled_at',
  CreatedAt = 'created_at',
  Title = 'title',
  TitleDraft = 'title_draft',
  Likes = 'likes',
  ReadingTime = 'reading_time',
}

/** SortEnum */
export enum KSchemasCategorySortEnum {
  CreatedAt = 'created_at',
}

/** FilterEnum */
export enum KSchemasCommentFilterEnum {
  Public = 'public',
}

/** SortEnum */
export enum KSchemasCommentSortEnum {
  CreatedAt = 'created_at',
}

/** FilterEnum */
export enum KSchemasNewsletterFilterEnum {
  Public = 'public',
}

/** SortEnum */
export enum KSchemasNewsletterSortEnum {
  CreatedAt = 'created_at',
}

/** FilterEnum */
export enum KSchemasSubscriptionFilterEnum {
  Free = 'free',
  Paid = 'paid',
  Monthly = 'monthly',
  Yearly = 'yearly',
  Premium = 'premium',
  Active = 'active',
  Unsubscribed = 'unsubscribed',
}

/** SortEnum */
export enum KSchemasSubscriptionSortEnum {
  SubscriptionDate = 'subscription_date',
  Unsubscribed = 'unsubscribed',
  SubscriptionLevel = 'subscription_level',
  FirstName = 'first_name',
  LastName = 'last_name',
  CreatedAt = 'created_at',
}

export type KSigninOauthRegisterPostData = KToken

export type KSigninOauthRegisterPostError = KHTTPValidationError

export type KChecklinkOauthChecklinkSegmentGetData = KToken

export type KChecklinkOauthChecklinkSegmentGetError = KHTTPValidationError

/** Body */
export type KTokenOauthTokenPostPayload = KBodyTokenOauthTokenPost

export type KTokenOauthTokenPostData = KToken

export type KTokenOauthTokenPostError = KHTTPValidationError

export type KLogoutOauthLogoutPostData = any

export interface KOauthAuthorizeStripeOauthAuthorizeStripeGetParams {
  /** Code */
  code: string
  /** State */
  state: string
  /** Error */
  error?: string | null
  /** Error Description */
  error_description?: string | null
  /** Callback Url */
  callback_url?: string | null
}

export type KOauthAuthorizeStripeOauthAuthorizeStripeGetData = any

export type KOauthAuthorizeStripeOauthAuthorizeStripeGetError = KHTTPValidationError

export type KGetMeUserMeGetData = KUserRead

export interface KListSubscriptionsUserSubscriptionGetParams {
  /** Newsletter Id */
  newsletter_id?: string | null
  /**
   * Filter
   * @default ["active"]
   */
  filter?: KSchemasSubscriptionFilterEnum[]
  /**
   * Sort
   * @default "created_at"
   */
  sort?: KSchemasSubscriptionSortEnum
  /**
   * Sort Dir
   * @default "desc"
   */
  sort_dir?: string
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
}

export type KListSubscriptionsUserSubscriptionGetData = KPageSubscriptionRead

export type KListSubscriptionsUserSubscriptionGetError = KHTTPValidationError

export type KGetSubscriptionUserSubscriptionSubscriptionIdGetData = KSubscriptionRead

export type KGetSubscriptionUserSubscriptionSubscriptionIdGetError = KHTTPValidationError

export interface KListMyNewslettersUserNewsletterGetParams {
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
}

export type KListMyNewslettersUserNewsletterGetData = KPageNewsletterRead

export type KListMyNewslettersUserNewsletterGetError = KHTTPValidationError

export type KPostArticleLikeUserArticleArticleIdLikePostData = KLikeRead

export type KPostArticleLikeUserArticleArticleIdLikePostError = KHTTPValidationError

/** Response Get Article Like User Article  Article Id  Like Get */
export type KGetArticleLikeUserArticleArticleIdLikeGetData = KLikeRead | null

export type KGetArticleLikeUserArticleArticleIdLikeGetError = KHTTPValidationError

/** Response Delete Article Like User Article  Article Id  Like Delete */
export type KDeleteArticleLikeUserArticleArticleIdLikeDeleteData = KLikeRead | null

export type KDeleteArticleLikeUserArticleArticleIdLikeDeleteError = KHTTPValidationError

export type KCreateCommentUserCommentPostData = KCommentRead

export type KCreateCommentUserCommentPostError = KHTTPValidationError

export type KGetCommentUserCommentCommentIdGetData = KCommentRead

export type KGetCommentUserCommentCommentIdGetError = KHTTPValidationError

export type KUpdateCommentUserCommentCommentIdPutData = KCommentRead

export type KUpdateCommentUserCommentCommentIdPutError = KHTTPValidationError

export type KDeleteCommentUserCommentCommentIdDeleteData = KCommentRead

export type KDeleteCommentUserCommentCommentIdDeleteError = KHTTPValidationError

export type KPostCommentLikeUserCommentCommentIdLikePostData = KLikeRead

export type KPostCommentLikeUserCommentCommentIdLikePostError = KHTTPValidationError

/** Response Get Comment Like User Comment  Comment Id  Like Get */
export type KGetCommentLikeUserCommentCommentIdLikeGetData = KLikeRead | null

export type KGetCommentLikeUserCommentCommentIdLikeGetError = KHTTPValidationError

export type KDeleteCommentLikeUserCommentCommentIdLikeDeleteData = any

export type KDeleteCommentLikeUserCommentCommentIdLikeDeleteError = KHTTPValidationError

/** Response Get Newsletter By Subdomain Subdomain  Subdomain Name  Get */
export type KGetNewsletterBySubdomainSubdomainSubdomainNameGetData = KNewsletterRead | null

export type KGetNewsletterBySubdomainSubdomainSubdomainNameGetError = KHTTPValidationError

export interface KListCategoriesCategoryGetParams {
  /**
   * Sort
   * @default "created_at"
   */
  sort?: KSchemasCategorySortEnum
  /**
   * Sort Dir
   * @default "desc"
   */
  sort_dir?: string
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
}

export type KListCategoriesCategoryGetData = KPageCategoryRead

export type KListCategoriesCategoryGetError = KHTTPValidationError

/** Response Get Newsletter By Subdomain Newsletter Domain  Subdomain Name  Get */
export type KGetNewsletterBySubdomainNewsletterDomainSubdomainNameGetData = KNewsletterRead | null

export type KGetNewsletterBySubdomainNewsletterDomainSubdomainNameGetError = KHTTPValidationError

export interface KListNewslettersNewsletterGetParams {
  /**
   * Filter
   * @default []
   */
  filter?: KSchemasNewsletterFilterEnum[]
  /**
   * Sort
   * @default "created_at"
   */
  sort?: KSchemasNewsletterSortEnum
  /**
   * Sort Dir
   * @default "desc"
   */
  sort_dir?: string
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
}

export type KListNewslettersNewsletterGetData = KPageNewsletterRead

export type KListNewslettersNewsletterGetError = KHTTPValidationError

export type KGetNewsletterNewsletterNewsletterIdGetData = KNewsletterRead

export type KGetNewsletterNewsletterNewsletterIdGetError = KHTTPValidationError

export interface KListArticlesNewsletterNewsletterIdArticleGetParams {
  /**
   * Filter
   * @default []
   */
  filter?: KSchemasArticleFilterEnum[]
  /**
   * Sort
   * @default "created_at"
   */
  sort?: KSchemasArticleSortEnum
  /**
   * Sort Dir
   * @default "desc"
   */
  sort_dir?: string
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
  /** Newsletter Id */
  newsletterId: string
}

export type KListArticlesNewsletterNewsletterIdArticleGetData = KPageArticleReadLight

export type KListArticlesNewsletterNewsletterIdArticleGetError = KHTTPValidationError

export type KGetArticleNewsletterNewsletterIdArticleArticleIdGetData = KArticleRead

export type KGetArticleNewsletterNewsletterIdArticleArticleIdGetError = KHTTPValidationError

export interface KListCommentsNewsletterNewsletterIdArticleArticleIdCommentGetParams {
  /**
   * Filter
   * @default []
   */
  filter?: KSchemasCommentFilterEnum[]
  /**
   * Sort
   * @default "created_at"
   */
  sort?: KSchemasCommentSortEnum
  /**
   * Sort Dir
   * @default "desc"
   */
  sort_dir?: string
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
  /** Article Id */
  articleId: string
  newsletterId: string
}

export type KListCommentsNewsletterNewsletterIdArticleArticleIdCommentGetData = KPageCommentRead

export type KListCommentsNewsletterNewsletterIdArticleArticleIdCommentGetError = KHTTPValidationError

export type KGetCommentNewsletterNewsletterIdArticleArticleIdCommentCommentIdGetData = KCommentRead

export type KGetCommentNewsletterNewsletterIdArticleArticleIdCommentCommentIdGetError = KHTTPValidationError

export interface KGetRecommendationNewsletterNewsletterIdRecommendationGetParams {
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
  /** Newsletter Id */
  newsletterId: string
}

export type KGetRecommendationNewsletterNewsletterIdRecommendationGetData = KPageNewsletterRead

export type KGetRecommendationNewsletterNewsletterIdRecommendationGetError = KHTTPValidationError

export type KGetOfferNewsletterNewsletterIdOfferGetData = KStripeOfferRead

export type KGetOfferNewsletterNewsletterIdOfferGetError = KHTTPValidationError

export type KGetOfferStripeOfferNewsletterIdGetData = KStripeOfferRead

export type KGetOfferStripeOfferNewsletterIdGetError = KHTTPValidationError

export interface KGetOfferLegacyStripeOfferGetParams {
  /** News Id */
  news_id: string
}

export type KGetOfferLegacyStripeOfferGetData = KStripeOfferRead

export type KGetOfferLegacyStripeOfferGetError = KHTTPValidationError

export interface KListMyNewslettersDashboardGetParams {
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
}

export type KListMyNewslettersDashboardGetData = KPageNewsletterReadOwner

export type KListMyNewslettersDashboardGetError = KHTTPValidationError

export type KGetNewsletterDashboardNewsletterIdGetData = KNewsletterReadOwner

export type KGetNewsletterDashboardNewsletterIdGetError = KHTTPValidationError

export interface KListArticlesDashboardNewsletterIdArticleGetParams {
  /**
   * Filter
   * @default []
   */
  filter?: KSchemasArticleFilterEnum[]
  /**
   * Sort
   * @default "created_at"
   */
  sort?: KSchemasArticleSortEnum
  /**
   * Sort Dir
   * @default "desc"
   */
  sort_dir?: string
  /**
   * Q
   * @default ""
   */
  q?: string
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
  /** Newsletter Id */
  newsletterId: string
}

export type KListArticlesDashboardNewsletterIdArticleGetData = KPageArticleReadOwner

export type KListArticlesDashboardNewsletterIdArticleGetError = KHTTPValidationError

export type KGetArticleDashboardNewsletterIdArticleArticleIdGetData = KArticleReadOwner

export type KGetArticleDashboardNewsletterIdArticleArticleIdGetError = KHTTPValidationError

export type KGetArticleStatDashboardNewsletterIdArticleArticleIdStatGetData = KArticleStat

export type KGetArticleStatDashboardNewsletterIdArticleArticleIdStatGetError = KHTTPValidationError

export type KGetArticleEmailStatDashboardNewsletterIdArticleArticleIdStatOpeningRateGetData = KOpeningRateStat

export type KGetArticleEmailStatDashboardNewsletterIdArticleArticleIdStatOpeningRateGetError = KHTTPValidationError

export interface KListSubscriptionsDashboardNewsletterIdSubscriptionGetParams {
  /**
   * Filter
   * @default "active"
   */
  filter?: KSchemasSubscriptionFilterEnum[]
  /**
   * Sort
   * @default "created_at"
   */
  sort?: KSchemasSubscriptionSortEnum
  /**
   * Sort Dir
   * @default "desc"
   */
  sort_dir?: string
  /**
   * Q
   * @default ""
   */
  q?: string
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
  /** Newsletter Id */
  newsletterId: string
}

export type KListSubscriptionsDashboardNewsletterIdSubscriptionGetData = KPageSubscriptionReadLight

export type KListSubscriptionsDashboardNewsletterIdSubscriptionGetError = KHTTPValidationError

export interface KListProductsDashboardNewsletterIdOfferProductGetParams {
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
  /** Newsletter Id */
  newsletterId: string
}

export type KListProductsDashboardNewsletterIdOfferProductGetData = KPageStripeProduct

export type KListProductsDashboardNewsletterIdOfferProductGetError = KHTTPValidationError

export type KCreateProductDashboardNewsletterIdOfferProductPostData = KStripeProduct

export type KCreateProductDashboardNewsletterIdOfferProductPostError = KHTTPValidationError

export type KUpdateProductDashboardNewsletterIdOfferProductProductIdPutData = KStripeProduct

export type KUpdateProductDashboardNewsletterIdOfferProductProductIdPutError = KHTTPValidationError