import { render, staticRenderFns } from "./publication.vue?vue&type=template&id=0d380966&"
import script from "./publication.vue?vue&type=script&lang=ts&"
export * from "./publication.vue?vue&type=script&lang=ts&"
import style0 from "./publication.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarDashboard: require('/tmp/build_6043b71c/apps/web/components/navbar/NavbarDashboard.vue').default,KFooter: require('/tmp/build_6043b71c/apps/web/components/KFooter.vue').default})
