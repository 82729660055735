import { Subscription, SubscriptionsParameters, SwaggerV2 } from '@kessel/core'
import { defineStore, storeToRefs } from 'pinia'
import axios from 'axios'
import { useTrackEvent } from '~/composables/useTrackEvent'
import { useOffer } from '~/stores/offer'

type SubscriptionUserQuery = SwaggerV2.KListSubscriptionsUserSubscriptionGetParams

interface State {
  subscriptions: Subscription[]
  subscription: Subscription | null
  subscriptionToken: Subscription | null
}

export const useSubscription = defineStore('subscription_v2', {
  state: (): State => ({
    subscriptions: [],
    subscription: null,
    subscriptionToken: null,
  }),
  actions: {
    async syncSubscriptions(params?: SubscriptionsParameters) {
      this.subscriptions = (await this.getSubscriptions(params)).items
    },
    async syncSubscription(subId: string) {
      const { $patch } = this
      const subscription = await this.getSubscription(subId)
      $patch({ subscription })
    },
    async syncSubscriptionFromToken(subId: string, token: string) {
      const { $patch } = this
      try {
        // The next call is done with a pristine instance of axios,
        // in order to avoid global configuration to add cookies or other authentication headers
        const response = await axios.get(`${this.$nuxt.$axios.defaults.baseURL}/v2/user/subscription/${subId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        $patch({ subscriptionToken: response.data })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(`Error while loading axios without base config: ${err}`)
        if (axios.isAxiosError(err) && err.response) {
          if (err.response.status === 404) {
            $patch({ subscriptionToken: null })
            return
          }
          // eslint-disable-next-line no-console
          console.log(err.response)
        }
        throw err
      }
    },
    async getSubscriptions(params?: SubscriptionUserQuery) {
      return await this.$nuxt.$axios.$get('/v2/user/subscription', { params })
    },
    async getSubscription(subId: string) {
      return await this.$nuxt.$axios.$get(`/v2/user/subscription/${subId}`)
    },
    async getSubscriptionByPublicationId(newsId: string) {
      const { total, items } = await this.$nuxt.$axios.$get('/v2/user/subscription', {
        params: {
          newsletter_id: newsId,
        },
      })

      return { subscribed: Boolean(total > 0), subscription: (items.at(0) as Subscription) || null }
    },
    async unsubscribe(subId: string) {
      await this.$nuxt.$axios.$delete(`/v1/subscription/${subId}`)
      this.$patch({ subscriptionToken: null })
    },
    async unsubscribeMail(subId: string, mailingEnabled: boolean) {
      return await this.$nuxt.$axios.$put(`/v1/subscription/${subId}`, {
        mailing_enabled: mailingEnabled,
      })
    },

    async unsubscribeMailFromToken(subId: string, mailingEnabled: boolean, token: string) {
      return await this.$nuxt.$axios.$put(
        `/v2/user/subscription/${subId}`,
        { mailing_enabled: mailingEnabled },
        {
          params: { token },
        }
      )
    },
    async attachPlan(pubId: string, priceId = 'price_free') {
      const { trackEvent } = useTrackEvent()

      const { products, getReferralPubliation } = storeToRefs(useOffer())
      const referralToken = getReferralPubliation.value(pubId)

      if (products.value) {
        const prices = products.value && products.value.map(({ prices }) => prices).flat(1)

        const foundedKey = prices.find(({ id }) => id === priceId)
        const priceValue: number | null = foundedKey?.unit_amount || 0

        trackEvent('begin_checkout', {
          currency: 'EUR',
          value: priceValue / 100,
          items: [
            {
              item_id: priceId,
              quantity: 1,
            },
          ],
        })
      }

      return await this.$nuxt.$axios.$post('/v1/subscription/attach_plan', null, {
        params: {
          news_id: pubId,
          ...(priceId && { price_id: priceId }),
          ...(referralToken && { referral_token: referralToken }),
        },
      })
    },
  },
})
