import { render, staticRenderFns } from "./PaywallSeparator.vue?vue&type=template&id=325b2f23&scoped=true&"
import script from "./PaywallSeparator.vue?vue&type=script&lang=ts&"
export * from "./PaywallSeparator.vue?vue&type=script&lang=ts&"
import style0 from "./PaywallSeparator.vue?vue&type=style&index=0&id=325b2f23&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "325b2f23",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/tmp/build_6043b71c/apps/web/components/editor/components/Button.vue').default})
