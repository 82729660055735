import { render, staticRenderFns } from "./PublicationLanding.vue?vue&type=template&id=5721cbb0&"
import script from "./PublicationLanding.vue?vue&type=script&lang=ts&"
export * from "./PublicationLanding.vue?vue&type=script&lang=ts&"
import style0 from "./PublicationLanding.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthorPicture: require('/tmp/build_6043b71c/apps/web/components/author/AuthorPicture.vue').default,KButton: require('/tmp/build_6043b71c/apps/web/components/buttons/KButton.vue').default,DirectSignup: require('/tmp/build_6043b71c/apps/web/components/form/DirectSignup.vue').default,LandingInvitation: require('/tmp/build_6043b71c/apps/web/components/landing/LandingInvitation.vue').default,PostCover: require('/tmp/build_6043b71c/apps/web/components/post/PostCover.vue').default})
