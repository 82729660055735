import { render, staticRenderFns } from "./KUploadFile.vue?vue&type=template&id=0f895ac3&scoped=true&"
import script from "./KUploadFile.vue?vue&type=script&lang=ts&"
export * from "./KUploadFile.vue?vue&type=script&lang=ts&"
import style1 from "./KUploadFile.vue?vue&type=style&index=1&id=0f895ac3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f895ac3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KButton: require('/tmp/build_6043b71c/apps/web/components/buttons/KButton.vue').default,KIcon: require('/tmp/build_6043b71c/apps/web/components/icons/KIcon.vue').default,KMessage: require('/tmp/build_6043b71c/apps/web/components/KMessage.vue').default,KImage: require('/tmp/build_6043b71c/apps/web/components/image/KImage.vue').default,KTooltip: require('/tmp/build_6043b71c/apps/web/components/KTooltip.vue').default})
