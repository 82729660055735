import { BaseUser, Subscription } from '../models'

export enum SignType {
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
}

export enum PostVisibilityLevel {
  PUBLIC = -1,
  SUBSCRIBERS = 0,
  PAID_SUBSCRIBERS = 1,
}

export interface PostStatus {
  level: PostVisibilityLevel
  icon: string
  text: string
}

export enum StripeAccountStatus {
  UNFINISHED = 'unfinished',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export const EXTERNAL_URL_REGEX = /^http[s]?:\/\/(www\.)?((?!kessel).*)/
export const TYPE_IMAGE_GIF_REGEX = /image\/gif/

export const DEFAULT_BACKEND_RESIZE_IMAGE = 1280 // 1280 is the default resize value for the backend side

export const OFFER = {
  TVA: 0.2,
  AUTHOR_MARGE: 0.9,
  DEFAULT_MONTHLY_PRICE: 700,
  MONTH_IN_YEAR: 10,
  STRIPE_TAX: 0.25,
  KESSEL_TAX: 0.886,
  // TODO no idea what is that
  PREMIUM_UNKNOWN_VALUE: 21.286,
  PREMIUM_MAX_PRICE: 1000,
}

export const ESTIMATION = {
  DEFAULT_PRICE: 5,
  SLIDER_USER_STEP: 50,
  SLIDER_USER_MIN: 0,
  SLIDER_USER_MAX: 10000,
  SUBSCRIPTION_PRICE_STEP: 0.01,
  SUBSCRIPTION_PRICE_MIN: 2,
  SUBSCRIPTION_PRICE_MAX: 1000,
}

export enum SubscriptionLevel {
  UNKNOWN = -1,
  FREE = 0,
  MONTHLY = 1,
  YEARLY = 2,
  PREMIUM = 3,
  ONE_TIME = 4,
}

export enum SubscriptionLevelName {
  UNKNOWN = 'SUBSCRIBE',
  FREE = 'SUB_FREE',
  MONTHLY = 'SUB_PAID',
  YEARLY = 'SUB_PAID',
  PREMIUM = 'SUB_PREMIUM',
}

export enum PaymentStatus {
  UNKNOWN = 'UNKNOWN',
  ACTIVE = 'ACTIVE',
  INCOMPLETE = 'INCOMPLETE',
  PAST_DUE = 'PAST_DUE',
  CANCELED = 'CANCELED',
  CANCELLATION = 'CANCELLATION',
}

export interface ImageCropped {
  filename: string
  x?: number
  y?: number
  width?: number
  height?: number
}

export interface Domain {
  name: string
}

export interface StripeAccount {
  status: 'enabled' | 'unfinished'
  stripe?: {
    email: string
  }
}

export type TSigninForm = {
  email: string
  password?: string
  withPassword: boolean
}

export interface MetaBuilder {
  title: string
  description: string
  type: 'website' | 'article' | 'blog' // http://ogp.me/#types for more
  image?: ImageCropped
  author?: string
  publishedTime?: string // year-month-dayT00:00
  themeColor?: string
  twitterCard?: 'summary' | 'summary_large_image' | 'app' | 'player'
  twitterImage?: ImageCropped
  twitterCreator?: string
  locale?: 'fr_FR' | 'en_US' // https://www.ibm.com/docs/en/radfws/9.6.1?topic=overview-locales-code-pages-supported for more
}

export interface MetaInfoHostEnv {
  path: {
    subdomain: string
    canonical: string
  }
}

export const DEFAULT_LOGO_PLACEHOLDER = 'Kessel_K_flat.svg'

export const DESCRIPTION_MAX_LENGTH = 200

export interface Parameters {
  name: string
  page?: number
  size?: number
  filter?: string
  sort?: string
  sort_dir?: 'asc' | 'desc'
}

export interface Pagination {
  items: object[] // TODO: use Object[] in typescript < 2.2
  total: number
  page: number
  size: number
}

export enum PaginationNames {
  NEWSLETTER_POSTS = 'newsletter-posts',
  NEWSLETTER_POSTS_PINNED = 'newsletter-posts-pinned',
  NEWSLETTER_POST_COMMENTS = 'newsletter-post-comments',
  CATEGORY_POSTS = 'category-posts',
  DASHBOARD_SUBSCRIPTIONS = 'dashboard-subscriptions',
  DASHBOARD_POSTS = 'dashboard-posts',
  DASHBOARD_POSTS_PINNED = 'dashboard-posts-pinned',
  DASHBOARD_DRAFTS = 'dashboard-drafts',
  DASHBOARD_SUBSCRIBERS = 'dashboard-subscribers',
}

export interface AuthPayload {
  user: Partial<BaseUser>
  subscription: Partial<Subscription>
  scope: 'all' | 'newsletter'
}

export enum UnsubscribeType {
  DELETE = 'delete',
  FREE = 'free',
}

export const IMAGE_MAX_SIZE = 5 // in Mo
export const RECO_MAX_SIZE = 4
export const MAX_CATEGORIES = 3
