








































import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'

import { useCustomTheme } from '~/composables/useTheme'
import { usePost } from '~/stores/post_v2'
import { usePublication } from '~/stores/publication_v2'
import { useRights } from '~/stores/right'
import { generateLinear } from '~/utils/color'

export default defineComponent({
  name: 'PostsParentPage',
  setup() {
    const { shouldOpenSubscriptionDrawer, shouldOpenRecommendPrompt } = storeToRefs(useRights())

    const { publication, recommendations } = storeToRefs(usePublication())

    const { post } = storeToRefs(usePost())

    const route = useRoute()

    useCustomTheme()

    const showLanding = computed(() => !!route.value.query?.landing)
    const linearColor = computed(() => publication.value && generateLinear(publication.value?.color_primary))

    // eslint-disable-next-line no-console
    console.log('Rendering Newsletter layout with:', JSON.stringify({ showLanding: showLanding.value, publication: publication.value }))
    return {
      showLanding,
      publication,
      recommendations,
      linearColor,
      shouldOpenRecommendPrompt,
      shouldOpenSubscriptionDrawer,
      isPost: computed(() => !!post.value),
    }
  },
  head: {},
})
