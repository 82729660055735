import { Offer, Product } from '@kessel/core'
import { defineStore, storeToRefs } from 'pinia'
import { usePublication } from '~/stores/publication_v2'

// This interface must remain private
interface State {
  offer: Offer | null
  referalToken: { [key: string]: string }
  products: Product[]
}

/*

free -> ---
month -> interval month + interval_count 1 + premium false
year -> interval year + interval_count 1 + premium false
premium -> interval year + interval_count 1 + premium true
pnctual -> one time

*/

export const useOffer = defineStore('offer_v2', {
  state: (): State => ({
    offer: null,
    products: [],
    referalToken: {},
  }),
  getters: {
    getReferralPubliation: (state) => (pubId: string) => state.referalToken[pubId as string],
    paymentEnabled: (state) =>
      [
        ...(state.products && (state.products.map(({ prices }) => prices).flat(1))),
      ].filter(({ unit_amount: unitAmount }) => unitAmount > 0).length > 0,
  },
  actions: {
    setReferalToken(referalToken: string, pubId: string) {
      this.referalToken[pubId] = referalToken
    },
    async syncOffer() {
      const { $patch, getProducts } = this
      const { publication } = storeToRefs(usePublication())
      if (publication.value?.id) {
        const { products }: { products: Product[] } = await getProducts(publication.value?.id as string)

        $patch({
          products,
        })
      }
    },
    async getProducts(newsId: string) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$get(`/v2/newsletter/${newsId}/offer`)
    },
  },
})
