import { BaseUser, Publication, PutUser, PutUserValidationSchema } from '@kessel/core'
import { Context } from '@nuxt/types'
import Ajv from 'ajv'
import pick from 'lodash/pick'
import { defineStore, storeToRefs } from 'pinia'
import { usePublication } from '~/stores/publication'

const ajv = new Ajv()
const validate = ajv.compile(PutUserValidationSchema)

interface State {
  user: BaseUser | null
}

export const useUser = defineStore('user', {
  state: (): State => ({
    user: null,
  }),
  getters: {
    isLogged(state) {
      return !!state.user
    },
  },
  actions: {
    async syncUser(redirect = false) {
      try {
        this.$patch({
          user: await this.getUser(),
        })
      } catch (e) {
        if (redirect) {
          const { redirect, localePath } = this.$nuxt as Context
          redirect(localePath({ name: 'login' }))
        }
      }
    },
    async getUser() {
      return await this.$nuxt.$axios.$get('/v2/user/me')
    },
    async putUser(user: PutUser) {
      const putProfile = pick(user, Object.keys(PutUserValidationSchema.properties))
      const isValid = validate(putProfile)

      if (!isValid) {
        console.error(validate.errors)
        throw new Error(`Validation Error. ${JSON.stringify(validate.errors)}`)
      }

      this.putLocalUser(putProfile)
      return await this.$nuxt.$axios.$put('/v1/user/profile', putProfile)
    },
    putLocalUser(user: Partial<PutUser>) {
      this.$patch({ user })
    },
    isOwnerOfPublication(pubId: string) {
      const { publications, publication } = storeToRefs(usePublication())

      return publication.value?.is_owner || publications.value.find(({ id }: Publication) => id === pubId)?.is_owner
    },
  },
})
